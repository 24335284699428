import axios from 'axios';
const url = window.location.search;
const getParam = new URLSearchParams(url);
const getToken = getParam.get('token')
const getName = getParam.get('name')
const getImage = getParam.get('image')
if(getToken!=undefined){
    sessionStorage.setItem("token",getToken); 
    sessionStorage.setItem("name",getName);
    sessionStorage.setItem("profile_pic",getImage); 
}
const token =  sessionStorage.getItem("token"); 
const name =  sessionStorage.getItem("name"); 
export default axios.create({
    
    baseURL:process.env.REACT_APP_BASE_URL,
    headers:{
        'Authorization': 'Bearer '+token,
        'Content-Type': 'application/json',
    },
    
});